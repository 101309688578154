@use "../../../../settings/Settings";

.Content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.InfoWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .Info {
    .Label {
      color: #999;
    }

    .Value {
      &.Null {
        color: rgba(255, 255, 255, 0.5);
        font-style: italic;
      }
    }
  }
}

.Divider {
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
}

.Footer {
  display: flex;
  justify-content: flex-start;
}
