@use "../../../settings/Settings";

.Table {
  padding: 20px 0 0 20px;
  p {
    margin: 0;
  }
}

$columnPadding: 7px 0 7px 10px;

.Header {
  display: grid;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: $columnPadding;

  .HeaderColumn {
    .HeaderColumnInner {
      user-select: none;
      display: flex;
      align-items: center;
      font-size: 14px;
      gap: 5px;
      font-weight: 500;
      cursor: pointer;
      color: grey;
      .SortIcon {
        font-size: 16px !important;
        opacity: 0;
        &.Active {
          opacity: 1;
        }
        &.Asc {
          transform: rotate(0deg);
        }
        &.Desc {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.Row {
  display: grid;
  cursor: default;
  user-select: none;
  padding: $columnPadding;

  &:hover {
    background-color: Settings.$darkGrey;
  }

  &.Active {
    background-color: Settings.$paleBlue;
  }
  .Cell {
    grid-column-start: auto;
    display: flex;
    align-items: center;
    gap: 5px;
    color: white;

    &.Null {
      color: rgba(234, 234, 234, 0.5);
    }

    .Text {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .FavouriteWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    border-radius: 3px;
    &:hover {
      background-color: Settings.$blueHoverColor;
    }
    .StarOutline {
      color: rgb(137, 137, 137);
    }
    .FilledStar {
      color: Settings.$favouriteGoldColor;
    }
  }
}
