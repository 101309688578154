@use "../../../settings/Settings";

.ContextBar {
  height: Settings.$contextBarHeight;
  border-bottom: 1px solid Settings.$darkGrey;
  background-color: Settings.$darkThemeSecondColor;
  width: 100%;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  color: white;
  padding: 0 20px;
  z-index: 1;
  p {
    margin: 0;
  }
}

.Route {
  cursor: default;
  display: flex;

  .Link {
    display: flex;
    text-decoration: none;
    &:active, &:visited, &:link {
      color: inherit;

    }
    &.Clickable {
      cursor: pointer;
      :hover {
        text-decoration: underline;
        color: Settings.$lightGrey;
      }
    }

  }
  .Separator {
    margin: 0 10px;
  }
}
