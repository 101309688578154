@import '../../settings/_Settings';

.SizeItUpView {
  position: absolute;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;

  background-color: $darkThemeSecondColor;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  text-align: center;
  color: white;
  font-size: 18px;
  user-select: none;

  > img {
    filter: brightness(0) invert(1);
    max-width: 100px;
    max-height: 100px;
    user-select: none;
    margin: 25px;
  }

  > p {
    &.extraBold {
      font-size: 18px;
      font-weight: 600;
    }
  }
}