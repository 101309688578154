@use "../../../settings/Settings";

.LoaderOverlay {
  background-color: Settings.$darkThemeSecondColor;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .Inner {
    display: flex;
    flex-direction: column;
    align-items: center;

    .Msg {
      user-select: none;
      margin-top: 0.5rem;
      color: white;
    }
  }
}
