@use "../../../../settings/Settings";

.Content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DropzoneWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  position: relative;
}

.Actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
  .Action {
    flex: 1;
    .Button {
      width: 100%;
    }
  }
}

.Button {
  box-shadow: Settings.$secondaryColor 0 0 0 2px inset; // fallback if new css variables are not supported by browser
  background-color: Settings.$secondaryColor; // fallback if new css variables are not supported by browser
  color: white; // fallback if new css variables are not supported by browser

  &:hover {
    background-color: transparent;
    color: Settings.$secondaryColor; // fallback if new css variables are not supported by browser
  }

  &.disabled {
    box-shadow: Settings.$darkThemeThirdColor 0 0 0 2px inset;
    background-color: Settings.$darkThemeThirdColor;
    cursor: default;
    opacity: 0.6;

    &:hover {
      box-shadow: Settings.$darkThemeThirdColor 0 0 0 2px inset;
      background-color: Settings.$darkThemeThirdColor;
      color: white;
    }
  }
}

.LoaderWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
}
