@use "../../settings/Settings";

.Wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;

  .MainWrapper {
    background-color: Settings.$darkThemeSecondColor;
    height: 100%;
    width: 100%;

    .LeftBar {
      max-width: Settings.$leftBarWidth;
      min-width: Settings.$leftBarWidth;
      height: 100%;
      position: fixed;
      z-index: 1;
      top: Settings.$topNavigationBarHeight;
      background-color: Settings.$darkThemeSecondColor;
      left: 0;
      border-right: 1px solid Settings.$darkGrey;
      color: white;
      padding-top: 7px;
    }

    .Right {
      width: 100vw;
      max-height: 100vh;
      min-height: 100vh;
      position: fixed;
      padding-left: Settings.$leftBarWidth;
      padding-top: Settings.$topNavigationBarHeight;
      padding-bottom: 50px;
      overflow-y: overlay;

     
    }
  }
}
