@import '../../../settings/Settings';

.LabelControlPanel {
  position: absolute;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  .ImageButton {
    transition: transform 0.3s;

    img {
      filter: brightness(0) invert(1);
      user-select: none;
    }

    &:hover {
      background-color: transparent;
    }

    &:not(.disabled):hover {
      filter: brightness(35%) sepia(100%) hue-rotate(172deg) saturate(2000%); // fallback if new css variables are not supported by browser
      filter: brightness(35%) sepia(100%) hue-rotate(var(--hue-value)) saturate(2000%);

      &.right {
        transform: translate(2px);
      }

      &.left {
        transform: translate(-2px);
      }
    }

    &.disabled {
      img {
        filter: invert(1) opacity(25%);
        user-select: none;
      }
    }
  }

  .SuggestedLabel {
    align-self: stretch;

    justify-content: center;
    align-items: center;
    align-content: center;

    padding: 0 10px 0 15px;
    color: white;
  }

  $randomNumber: random(5);

  &.is-active {
    background-color: $darkThemeSecondColor;
    border: solid 1px $primaryColor;
    animation: brColor 5s infinite linear;
    animation-delay: $randomNumber + s;
    transform: translate(-15px, -15px);
    border-radius: 3px;
    min-width: 30px;
    height: 30px;
    z-index: 1000;
  }

  &:not(.is-active) {
    background-color: $primaryColor;
    animation: bgColor 5s infinite linear;
    animation-delay: $randomNumber + s;
    transform: translate(-6px, -6px);
    border-radius: 6px;
    width: 12px;
    height: 12px;
    z-index: 1;
  }
}

@keyframes bgColor {
  50% {
    background-color: $secondaryColor;
  }
  100% {
    background-color: $primaryColor;
  }
}

@keyframes brColor {
  50% {
    border-color: $secondaryColor;
  }
  100% {
    border-color: $primaryColor;
  }
}