@use "../../../../settings/Settings";

.Content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.FormGroupWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .FormGroup {
    dt {
      label {
        position: static !important
      }
      margin-bottom: 2px;
    }
    dd {
      .ErrorText {
        color: Settings.$errorColor;
      }
    }
  }
}

.Actions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  
}

.Divider {

}

.Footer {

}
