.ImageButton {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 50%;
  box-sizing: border-box;
  transition: background-color 0.7s ease;
  margin: 5px 2px;

  > img {
    user-select: none;
  }

  &:hover ~ .Cursor {
    width: 20px;
    height: 20px;
    border-color: transparent;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.ImageButton:not(.disabled):hover {
  cursor: pointer;
}

.ImageButton:hover {
  background-color: rgba(255, 255, 255, 0.3);
}