@use "../../../settings/Settings";

.TopNavigationBar {
  align-self: stretch;
  min-height: Settings.$topNavigationBarHeight;
  background-color: Settings.$darkThemeSecondColor;
  border-bottom: 1px solid Settings.$darkThemeThirdColor;

  display: flex;
  flex: 0 0 Settings.$topNavigationBarHeight;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;

  .TopNavigationBarWrapper {
    color: white;
    align-self: stretch;
    flex: 1;
    height: Settings.$topNavigationBarHeight;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;

    .NavigationBarGroupWrapper {
      align-self: stretch;
      display: flex;
      justify-content: center;
      align-items: center;

      &.middle {
        flex: auto;
      }

      &:not(:last-child) {
        border-right: solid 1px Settings.$darkThemeFourthColor;
      }
    }
  }
}

