@use "../../../settings/_Settings.scss";

.loader {
   display: flex;
   flex-direction: column;
   align-items: center;

   .MuiCircularProgress-root {
      margin-bottom: .5rem;
   }
}