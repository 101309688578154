@use "../../../settings/Settings";

.TextButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  line-height: 0;
  word-wrap: break-word;
  padding: 20px 30px;
  text-decoration: none;
  box-shadow: black 0 0 0 2px inset;
  border: none;
  color: black;
  margin: initial;
  border-radius: 2px;
  transition: 0.1s ease-in-out;
  background: transparent;
  font-weight: 700;
  position: relative;

  > a {
    text-decoration: none;
    color: inherit;
  }

  &.disabled {
    pointer-events: none;
    $disabledGrey: rgb(86, 86, 86);
    background-color: $disabledGrey;
    box-shadow: $disabledGrey 0 0 0 2px inset;
  }

  &:hover {
    color: white;

    > a {
      text-decoration: none;
      color: white;
    }
  }

  .loadingIcon {
    color: inherit;
    opacity: 0;
    position: absolute;
  }
  .content {
    display: flex;
    gap: 5px;
    align-items: center;

  }
  .content {
    opacity: 1;
  }

  &.loading {
    .loadingIcon {
      opacity: 1;
    }
    .content {
      opacity: 0;
    }
  }
}

.delete {
  color: white;
  box-shadow: Settings.$deleteColor 0 0 0 2px inset;
  background-color: Settings.$deleteColor;
  transition: all 0.1s;
  &:hover {
    background-color: transparent;
    color: Settings.$deleteColor;
    box-shadow: Settings.$deleteColor 0 0 0 2px inset;
  }
}

.update {
  color: white;
  box-shadow: Settings.$secondaryColor 0 0 0 2px inset;
  background-color: Settings.$secondaryColor;
  transition: all 0.1s;
  &:hover {
    background-color: transparent;
    color: Settings.$secondaryColor;
    box-shadow: Settings.$secondaryColor 0 0 0 2px inset;
  }
}

.cancel {
  color: rgb(167, 167, 167);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.1);
  transition: all 0.1s;
  &:hover {
    color: lightgrey;
    background-color: rgba(0, 0, 0, 0.2);
  }
}
