@use "../../settings/Settings";
@use "../../settings/globals";

.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  background-color: Settings.$darkThemeFirstColor;
}

.logo {
  object-fit: contain;
  width: 60px;
  margin-top: 50px;
}

.formWrapper {
  overflow-y: auto;
  width: 500px;
  background-color: Settings.$darkThemeSecondColor;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  min-height: 200px;
}

.header {
  border-bottom: 1px solid Settings.$darkThemeFirstColor;
  padding: 20px;
  h2 {
    margin: 0;
  }
}

.main {
  padding: 10px 30px 0 30px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer {
  background-color: inherit;
  padding: 30px;
  position: relative;
  top: -20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.link {
  cursor: pointer;
  color: rgb(62, 62, 236);
  text-decoration: none;
  &:hover {
    // make text lighter
    color: rgb(77, 77, 193);
  }
}

.invalid-credentials {
  color: red;
  font-size: 16px;
  margin-top: 20px;
}

.button {
  @include globals.whiteButton;
  height: 40px;
}
.HelperText {
  margin-top: 15px;
}
