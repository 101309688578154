@use "../../../settings/_Settings.scss";

@keyframes shake {
  0% {
    margin-left: 0rem;
  }
  25% {
    margin-left: 2rem;
  }
  75% {
    margin-left: -2rem;
  }
  100% {
    margin-left: 0rem;
  }
}

.CustomTextInput {
   label {
      display: static;
   }

   &.error {
    .helper-text {
      color: Settings.$errorColor;
    }
   }
}
