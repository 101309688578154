@use "../../../settings/Settings";

.wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: Settings.$darkThemeSecondColor;
}

.content {
  margin-top: 10px;
}

.box {
  height: 400px;
  width: 400px;
  background-color: Settings.$darkThemeThirdColor;
  padding: 15px;
}

.section {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid darken(Settings.$darkThemeThirdColor, 4%);

  display: flex;
  flex-direction: column;
  gap: 5px;
  .exportButton {
    padding: 10px 20px;
  }
}
