@use "../../../../settings/Settings";
.logoDropdown {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  cursor: pointer;
  padding: 0 12px;

  .logo {
    width: 17px;
  }
  .dropdownArrow {
    transition: 0.2s ease;
    font-size: 13px;
  }

  &.hover {
    background-color: Settings.$darkThemeFirstColor;
    .dropdownArrow {
      transform: translateY(3px);
    }
  }
}

.popover {
  .arrow {
    position: relative;
    margin-top: 5px;
    &::before {
      background-color: Settings.$darkThemeFirstColor;
      content: "";
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      top: -4px;
      transform: rotate(45deg);
      left: 14px;
    }
  }
  .popoverContent {
    background-color: Settings.$darkThemeFirstColor;
    color: white;
    ul {
      padding: 5px 0;
      li {
        font-size: 14px;
        cursor: pointer;
        width: 150px;

        padding: 2px 0px 2px 30px;
        &:hover {
          background-color: Settings.$blueHoverColor;
        }
      }
    }
  }
}
