@import "../../../settings/Settings";

.GenericYesNoPopup {
  max-height: 600px;
  max-width: 500px;
  width: 50%;
  border-radius: 5px;
  user-select: none;
  box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 0.02);

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;

  .Header {
    align-self: stretch;
    height: 40px;
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.4);
    color: white;
    font-weight: 900;
    font-size: 18px;
    background-color: $darkThemeFirstColor;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .Content {
    align-self: stretch;
    font-size: 14px;
    background-color: $darkThemeSecondColor;
    flex: 1;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .Footer {
    padding: 0 40px;
    height: 80px;
    align-self: stretch;
    background-color: $darkThemeSecondColor;
    border-top: solid 1px $darkThemeFirstColor;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    align-content: flex-end;

    .TextButton {
      margin-left: 20px;
      box-shadow: $secondaryColor 0 0 0 2px inset; // fallback if new css variables are not supported by browser
      background-color: $secondaryColor; // fallback if new css variables are not supported by browser
      color: white; // fallback if new css variables are not supported by browser

      &:hover {
        background-color: transparent;
        color: $secondaryColor; // fallback if new css variables are not supported by browser
      }

      &.disabled {
        box-shadow: $darkThemeThirdColor 0 0 0 2px inset;
        background-color: $darkThemeThirdColor;
        cursor: default;
        opacity: 0.6;

        &:hover {
          box-shadow: $darkThemeThirdColor 0 0 0 2px inset;
          background-color: $darkThemeThirdColor;
          color: white;
        }
      }
    }
  }
}
