@use "../../../../../settings/Settings";
.popupContent {
  width: 500px;
  background-color: Settings.$darkThemeSecondColor;
  display: flex;
  flex-direction: column;
}

.heading {
  background-color: Settings.$darkThemeFirstColor;
  height: 40px;
  padding: 15px;
  display: flex;
  align-items: center;
  font-size: 18px;
}

.main {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  padding: 15px;
}

.footer {
  border-top: 1px solid Settings.$darkThemeFirstColor;
  display: flex;
  justify-content: flex-end;
  padding: 15px;
}

.TextButton {
  margin-left: 20px;
  box-shadow: Settings.$secondaryColor 0 0 0 2px inset; // fallback if new css variables are not supported by browser
  background-color: Settings.$secondaryColor; // fallback if new css variables are not supported by browser
  color: white; // fallback if new css variables are not supported by browser

  &:hover {
    background-color: transparent;
    color: Settings.$secondaryColor; // fallback if new css variables are not supported by browser
  }

  &.disabled {
    box-shadow: Settings.$darkThemeThirdColor 0 0 0 2px inset;
    background-color: Settings.$darkThemeThirdColor;
    cursor: default;
    opacity: 0.6;

    &:hover {
      box-shadow: Settings.$darkThemeThirdColor 0 0 0 2px inset;
      background-color:Settings.$darkThemeThirdColor;
      color: white;
    }
  }
}
