.popupContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;

  letter-spacing: 0.1ch;

  input,
  select {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid aliceblue;
    padding: 5px;
    padding-left: 0;
    color: aliceblue;

    &:focus {
      outline: none;
    }
  }
}
