@use "./settings/_Settings";

.App {
  --leading-color: #{Settings.$secondaryColor};
  --button-text-color: #{white};
  --hue-value: 172deg;
}

.App.AI {
  --leading-color: #{Settings.$primaryColor};
  --button-text-color: #{Settings.$darkThemeSecondColor};
  --hue-value: 120deg;
}

/* width */
::-webkit-scrollbar {
  // width: 7px;
  width: 0px;
  height: 0px;
  z-index: 5;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(81, 81, 81);
  opacity: 0.3;
  border-radius: 10px;
  z-index: 5;
  visibility: hidden;

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: Settings.$linkColor;

  &:hover {
    color: Settings.$blueHoverColor;
  }
}

.MuiInput {
  .MuiFormControl-root {
    --asterisk-colour: rgb(198, 41, 41);
    --text-primary: rgb(240, 240, 240);
    --text-secondary: rgb(151, 151, 151);
    --focus-colour: rgba(53, 53, 53, 0.15);
    --unfocused-colour: rgba(0, 0, 0, 0.15);
    color: white;
    color-scheme: dark;

    .MuiFormLabel-root {
      color: var(--text-secondary);

      .MuiFormLabel-asterisk {
        color: var(--asterisk-colour);
      }
    }

    &.shake {
      animation-name: shake;
      animation-fill-mode: forwards;
      animation-duration: 0.3s;
      animation-timing-function: ease;
    }

    .MuiFilledInput-root {
      background-color: var(--unfocused-colour);
      color: var(--text-primary);
    }

    .MuiFilledInput-underline:before {
      border-bottom: 1px solid Settings.$secondaryColor;
    }
    .MuiFilledInput-underline:after {
      border-bottom: 2px solid #2737e5;
    }

    .MuiFormHelperText-root {
      color: var(--text-secondary);
      .asterisk {
        color: var(--asterisk-colour);
      }
    }

    &.Mui-focused {
      .MuiFormLabel-root {
        color: var(--text-primary);
      }

      .MuiFilledInput-root {
        background-color: var(--focus-colour);
      }
    }

    // removes the colour that google chrome autofill adds
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      color-scheme: dark;
      transition: background-color 600000s 0s, color 600000s 0s;
    }

    &:hover {
      .MuiFilledInput-root {
        background-color: var(--focus-colour);
      }
      .MuiFilledInput-underline:before {
        border-bottom: 1px solid Settings.$secondaryColor;
      }
    }
  }
}