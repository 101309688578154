$darkThemeFirstColor: #1c1c1c;
$darkThemeSecondColor: #282828;
$darkThemeThirdColor: #4c4c4c;
$darkThemeFourthColor: #262c2f;

$primaryColor: #00b2e9;
$secondaryColor: #0033a0;

$linkColor: #007bff;

$blueHoverColor: #3f8ae2;
$paleBlue: #4d5875;

$lightGrey: #e0e0e0;
$offWhite: #f5f5f5;
$darkGrey: #424242;

$errorColor: #ee0000;
$deleteColor: #dd0000;

$favouriteGoldColor: rgb(255, 215, 0);

// Account view
$topNavigationBarHeight: 45px;
$leftBarWidth: 240px;
$contextBarHeight: 50px;


$stateBarHeight: 2px;
$sideNavigationBarCompanionWidth: 23px;
$sideNavigationBarContentWidth: 300px;
$editorBottomNavigationBarHeight: 40px;
$editorTopNavigationBarHeight: 40px;
$toolboxWidth: 50px;

