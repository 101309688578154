.Content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.InfoWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  dl {
    dt {
      color: #999;
    }

    dl {
      &.Null {
        color: rgba(255, 255, 255, 0.5);
        font-style: italic;
      }
    }
  }
}
