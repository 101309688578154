@use "./Settings";

@mixin whiteButton {
  box-shadow: white 0 0 0 2px inset;
  color: white;

  &:hover {
    background-color: white;
    color: black;
  }
}

@mixin blackButton {
   box-shadow: black 0 0 0 2px inset;
   color: black;

   &:hover {
     background-color: black;
     color: white;
   }
}

