.RightBarContent {
   padding: 20px;
 }
 
 .Label {
   font-size: 20px;
 }
 .Description {
   font-size: 14px;
 }
 
 .stepper {
 }
 .step {
   .label {
     .text {
       font-size: 16px;
       color: white;
     }
   }
   .description {
     font-size: 14px;
   }
 }
 
 .nextSteps {
   margin-top: 30px;
 }
 