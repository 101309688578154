@use "../../../../settings/Settings";

.button {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
