@use "../../../settings/Settings";

.TopNavigationBar {
  height: Settings.$topNavigationBarHeight;
  background-color: Settings.$darkThemeSecondColor;
  border-bottom: 1px solid Settings.$darkThemeThirdColor;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.LogoWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;

  .Logo {
    display: flex;
    align-items: center;

    user-select: none;
    
    img {
      height: 18px;
      max-height: 100%;
      max-width: 100%;
    }
  }
}
