.path {
  user-select: none;
}

.link {
  cursor: pointer;
  color: white;

  &.grey {
    color: grey;
  }
  &:hover {
    color: white;
  }
}

.divider {
  margin: 0 10px;
  color: grey;
}
