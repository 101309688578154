@use "../../../settings/Settings";

.CustomDialog {
  width: 600px;
  user-select: none;
  box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 0.02);
  color: white;

  .Header {
    align-self: stretch;
    height: 60px;
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.4);
    color: white;
    font-weight: 900;
    padding-left: 20px;
    font-size: 22px;
    background-color: Settings.$darkThemeFirstColor;
    display: flex;
    align-items: center;
    position: relative;

    .Icon {
      margin-right: 5px;
      font-size: 25px;
    }

    .ClearIcon {
      position: absolute;
      right: 0;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 20px;
      color: white;
      z-index: 1;

      &:hover {
        background-color: Settings.$darkThemeSecondColor;
      }
    }
  }

  .Content {
    align-self: stretch;
    font-size: 16px;
    padding: 20px;
    background-color: Settings.$darkThemeSecondColor;
    flex: 1;
    color: white;

    .ErrorText {
      color: Settings.$errorColor;
    }
  }

  .Footer {
    padding: 0 20px;
    height: 80px;
    background-color: Settings.$darkThemeSecondColor;
    border-top: solid 1px Settings.$darkThemeFirstColor;
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;

    .TextButton {
      box-shadow: Settings.$secondaryColor 0 0 0 2px inset; // fallback if new css variables are not supported by browser
      background-color: Settings.$secondaryColor; // fallback if new css variables are not supported by browser
      color: white; // fallback if new css variables are not supported by browser

      &:hover {
        background-color: transparent;
        color: Settings.$secondaryColor; // fallback if new css variables are not supported by browser
      }

      &.disabled {
        box-shadow: Settings.$darkThemeThirdColor 0 0 0 2px inset;
        background-color: Settings.$darkThemeThirdColor;
        cursor: default;
        opacity: 0.6;

        &:hover {
          box-shadow: Settings.$darkThemeThirdColor 0 0 0 2px inset;
          background-color: Settings.$darkThemeThirdColor;
          color: white;
        }
      }
    }
  }
}
