.Text {
  margin-bottom: 20px;
}

.InputsWrapper {
  p {
    margin: 0;
  }
  .Input {
    width: 350px;
  }
  display: flex;
  flex-direction: column;
  gap: 20px;
}
