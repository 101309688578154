@use "../../settings/_Settings.scss";

.loader-overlay {
  background-color: Settings.$darkThemeSecondColor;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;

    .msg {
      margin-top: 0.5rem;
      color: white;
    }
  }
}
