.msgWrapper {
  display: flex;
  justify-content: flex-start;

  .msg {
    padding: 1rem;
    color: white;
    font-size: 17px;
    align-self: auto;
  }
}
