@use "../../../settings/Settings";

.Wrapper {  
  height: 100%;
}


.InputsWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 10px 0;
  p {
    margin: 0;
  }
  .Input {
    width: 350px;
  }
}
