.PreblastStepsStepper {
  .MuiStep-root {
    user-select: none;
    
    &.accessible {
      cursor: pointer;
      .MuiStepLabel-root {
        cursor: pointer;
      }
    }

    &.complete {
      &.active {
        .MuiSvgIcon-root {
          filter: brightness(1.5);
        }
      }
      &:hover {
        .MuiSvgIcon-root {
          // increase brightness
          filter: brightness(1.5);
        }
      }
    }

    &.accessible:not(.complete):not(.active) {
      .MuiSvgIcon-root {
        color: rgb(132, 132, 132);
      }

      &:hover {
        .MuiSvgIcon-root {
          // increase brightness
          filter: brightness(0.8);
        }
      }
    }

    &.active {
      &:hover {
        .MuiSvgIcon-root {
          // increase brightness
          filter: brightness(1.5);
        }
      }
    }

    // &:not(.disabled):not(.active):not(.complete) {
    //   .MuiSvgIcon-root {
    //     color: grey;
    //   }
    //   &:hover {
    //     .MuiSvgIcon-root {
    //      // increase brightness
    //      filter: brightness(.8);
    //     }
    //   }
    // }
  }
}
