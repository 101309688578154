@import "../../../settings/Settings";

.EditorBottomNavigationBar {
  align-self: stretch;
  height: $editorBottomNavigationBarHeight;
  border-top: solid 1px $darkThemeThirdColor;
  background-color: $darkThemeSecondColor;
  user-select: none;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  position: relative;

  &.with-context {
    background-color: $darkThemeFourthColor;
  }

  .ImageNavigationContainer {
    display: flex;
    flex-direction: row;

    .ImageButton {
      transition: transform 0.3s;

      img {
        filter: brightness(0) invert(1);
        user-select: none;
      }

      &:hover {
        background-color: transparent;
      }

      &:not(.disabled):hover {
        filter: brightness(35%) sepia(100%) hue-rotate(172deg) saturate(2000%); // fallback if new css variables are not supported by browser
        filter: brightness(35%) sepia(100%) hue-rotate(var(--hue-value))
          saturate(2000%);
        &.right {
          transform: translate(2px);
        }
        &.left {
          transform: translate(-2px);
        }
      }

      &.disabled {
        img {
          filter: invert(1) opacity(25%);
          user-select: none;
        }
      }
    }

    .CurrentImageName {
      min-width: 200px;
      padding: 0 20px;
      color: white;
      font-size: 14px;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
    }

    .CurrentImageCount {
      min-width: 80px;
      padding: 0 20px;
      color: white;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
  }

  .ScaleInputWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    .Text {
      color: aliceblue;
      display: flex;
      justify-content: center;
      align-items: center;
      justify-self: start;
    }

    .ScaleInput {
      font-weight: 200;
      margin-right: 5px;
      margin-left: 10px;
      user-select: none;
      color: aliceblue;
      height: 30px;
      max-width: 150px;
    
    }
  }

  .CustomPopup {
    margin: 0 1rem;
  }
}
