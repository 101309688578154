@use "../../../settings/Settings";

.ActionsWrapper {
   display: flex;
   gap: 20px;
   justify-content: flex-start;
   padding: 15px;
   position: sticky;
   z-index: 1;
   top:  Settings.$contextBarHeight;
   background-color: Settings.$darkThemeSecondColor;
 
   &.ShowScrolline {
     border-bottom: 1px solid Settings.$darkGrey;
     box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
   }
 }


.Action {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 15px 20px;
  height: 50px;
  transition: background-color .1s;
  border: 1px solid rgba(220, 220, 220, 0.5);

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    background-color: white;
    color: black;
  }

  &.Special {
    margin-left: auto;
    // the below styling is for a delete button
    // color: white;
    // &:hover {
    //   background-color: Settings.$erroColor;
    // }
  }

  .Icon {
    padding: 5px;
    font-size: 30px;
  }

  .Text {
    margin: 0;
  }


}