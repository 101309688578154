@use "../../../settings/Settings";

.wrapper {
   display: flex;
   justify-content: center;
   flex: 1;
   background-color: Settings.$darkThemeSecondColor;
 }
 
 .content {
   margin-top: 10px;
 }