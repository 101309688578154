@import '../../settings/_Settings';

.PopupView {
  position: absolute;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}