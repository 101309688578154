@use "../../../../settings/Settings";

.DialogContent {
  .ErrorText {
    color: Settings.$errorColor;
  }

  .InputsWrapper {
    margin-top: 15px;

    .Input {
      width: 300px;
      margin-bottom: 10px;
    }
  }

  .TextToType {
    font-style: italic;
    margin-right: 2px;
  }
}
