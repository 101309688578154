@use "../../../settings/Settings";

.LeftBar {
  &.ShowDropdownArrows {
    .ListHeader {
      .DropdownArrowWrapper {
        opacity: 1;
      }
    }
  }
}

.Link {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  user-select: none;
  gap: 10px;
  padding: 4px 10px;
  font-size: 14px;

  text-decoration: none;
  &:visited,
  &:active,
  &:link {
    color: inherit;
  }

  p {
    margin: 0;
  }

  .Icon {
    font-size: 16px;
  }

  &:hover {
    background-color: Settings.$darkGrey;
  }
  &.Active {
    background-color: Settings.$paleBlue;
  }
}

.ExpandableListWrapper {
  user-select: none;

  &.WithBorder {
    border-top: 1px solid Settings.$darkGrey;
    border-bottom: 1px solid Settings.$darkGrey;
    margin: 10px 0;
    padding: 10px 0;

    .ListHeader {
      padding: 10px;
    }
  }

  .ListHeader {
    display: flex;
    align-items: center;
    user-select: none;
    gap: 10px;
    width: 100%;

    .Icon {
      font-size: 18px;
    }

    .Text {
      font-size: 13px;
    }

    .DropdownArrowWrapper {
      margin-left: auto;
      opacity: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .Icon {
        font-size: 18px;
      }
      &.Open .Icon {
        transform: rotate(90deg);
      }
    }
  }

  .List {
    list-style: none;
    padding: 0;
    margin: 0;
    display: none;
    &.Open {
      display: block;
    }
    .FavouriteWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      padding: 3px;
      border-radius: 3px;
      &:hover {
        background-color: Settings.$blueHoverColor;
      }

      .Icon {
        font-size: 16px;
        color: Settings.$favouriteGoldColor;
      }
    }
    .StandardText {
      color: lightgrey;
    }

    .SpecialText {
      color: Settings.$linkColor;
    }
  }

  .HelperText {
    font-size: 12px;
    color: lightgrey;
    margin: 0 10px 0 20px;
  }
}
