@use "../../settings/Settings";

.AccountViewWrapper {
  
  .Divider {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.1);
  }

  .Footer {
    display: flex;
    justify-content: flex-start;
  }
}
