@use '../../settings/_Settings.scss';

.loader-overlay {

   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   background-color: rgba(0, 0, 0, 0.5);
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 10000;
   backdrop-filter: blur(5px);
   user-select: none;

   .inner {
      display: flex;
      flex-direction: column;
      align-items: center;

      .msg {
         margin-top: .5rem;
         color: white;
      }
   }
}