@use "../../../settings/Settings";

.leftNavigationBar {
  align-self: stretch;
  background-color: Settings.$darkThemeSecondColor;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  flex-direction: row;
  border-right: 1px solid Settings.$darkThemeThirdColor;

  .contentWrapper {
    align-self: stretch;
    min-width: Settings.$sideNavigationBarContentWidth;
    width: Settings.$sideNavigationBarContentWidth;
  }

  .draggableBorder {
    top: 0;
    right: 0;
    z-index: 1;
    cursor: ew-resize;
    position: absolute;
    background: transparent;
    width: 6px;
    height: 100%;
  }

  &.closed {
    border: none;
  }

  .closedBar {
    height: 100%;
    width: Settings.$sideNavigationBarCompanionWidth;
    border-right: 1px solid Settings.$darkThemeThirdColor;
  }
}

.contentWrapper {
  font-size: 14px;
}
