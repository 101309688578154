@use "../../../../settings/Settings";
.AccountWrapper {
  border-left: 1px solid Settings.$darkGrey;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 0 15px;
  height: 100%;
  user-select: none;

  &.Hover {
    background-color: Settings.$darkThemeFirstColor;
    .DownArrow {
      transform: translateY(3px);
    }
  }

  .DownArrow {
    transition: 0.2s ease;
    color: white;
    font-size: 16px;
  }
}

.AccountIconWrapper {
  color: Settings.$lightGrey;
  font-size: 24px;

  $accountIconDimensions: 30px;
  height: $accountIconDimensions;
  width: $accountIconDimensions;
  .IconImage {
    max-height: $accountIconDimensions;
    max-width: $accountIconDimensions;

    display: flex;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #747474;
  }

  .Icon {
    font-size: $accountIconDimensions;
    color: inherit;
  }
}

.DropDown {
  display: flex;
  background-color: Settings.$darkThemeSecondColor;
  position: relative;
  color: Settings.$offWhite;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding-bottom: 5px;

  .Top {
    margin: 0;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 8px;
    p {
      margin: 0;
    }
  }

  .List {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    li {
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 6px 10px;
      cursor: pointer;
      width: 100%;
      p {
        margin: 0;
      }
      &:hover {
        background-color: Settings.$blueHoverColor;
      }
    }

    .ListIcon {
      font-size: 16px;
    }
  }

  .Divider {
    width: 100%;
    height: 1px;
    background-color: Settings.$darkGrey;
    margin: 5px 0;
  }
}
