@use "../../../../settings/Settings";

.Content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.FormGroupWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .FormGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .Label {
      position: static !important;
      margin-bottom: 2px;
    }

    .ErrorText {
      color: Settings.$errorColor;
    }
  }
}

.Actions {
  margin-top: 20px;
  gap: 10px;
  display: flex;
  justify-content: flex-start;
}
