@use "../../settings/_Settings";

.MobileMainView {
  position: absolute;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;

  background-image: linear-gradient(
    to top right,
    Settings.$primaryColor,
    Settings.$secondaryColor
  );

  .MobileMainViewContent {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    justify-items: center;
    overflow: hidden;

    .FirstStage {
      position: relative;
      align-self: stretch;
      height: 400px;
      background-color: Settings.$darkThemeFirstColor;

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      justify-items: center;

      img {
        width: 100px;
        height: 100px;
        user-select: none;
      }

      .TriangleHorizontal.Bottom {
        .TriangleHorizontalContent {
          transform: skew(80deg) translateY(50%);
        }
      }
    }

    .SecondStage {
      align-self: stretch;
      padding-top: 100px;
      padding-bottom: 100px;

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      justify-items: center;

      .Message {
        width: 80%;
        max-width: 400px;
        margin-bottom: 50px;

        text-align: center;
        user-select: none;
      }

      .Link {
        text-decoration: underline;
        &:active, &:visited, &:link {
          color: Settings.$darkThemeFirstColor;
        }
      }
    }
  }

  .TriangleHorizontal {
    height: 600px;
    width: 100%;
    position: absolute;
    background-color: transparent;
    overflow: hidden;
    pointer-events: none;

    &.Top {
      top: -600px;
      .TriangleHorizontalContent {
        bottom: -300px;
      }
    }

    &.Bottom {
      bottom: -600px;
      .TriangleHorizontalContent {
        top: -300.1px;
      }
    }

    .TriangleHorizontalContent {
      height: 600px;
      width: 100%;
      position: absolute;
      left: 0;
      background-color: Settings.$darkThemeFirstColor;
    }
  }
}
