@import '../../../settings/Settings';

.ExitProjectPopupContent {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  padding-top: 30px;

  .Message {
    align-self: stretch;
    color: white;
    font-size: 15px;
    padding: 0 40px 30px 40px;
  }
}