@use "../../../settings/Settings";

.editorContainer {
  align-self: stretch;
  // flex: 0 0 calc(100% -  #{$topNavigationBarHeight});
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  position: relative;

  .editorWrapper {
    align-self: stretch;
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    transition: 0.2s ease;

    &.noClickthrough {
      pointer-events: none;
      filter: blur(2px);
      opacity: 0.5;
    }
  }
}

.dialogWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
