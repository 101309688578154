@use "../../../settings/mixins";
@use "../../../settings/Settings";

.Root {
  display: flex;
  flex-direction: column;
  gap: 15px;

  fieldset {
    border: 0;
    padding: 0;
  }
}

.Legend {
  margin-bottom: 5px;
}

.InputsWrapper {
  display: flex;
  gap: 15px;
}

.Input {
  color: white;

  .MuiFormControl-root {
   --asterisk-colour: rgb(198, 41, 41);
   --text-primary: rgb(240, 240, 240);
   --text-secondary: rgb(151, 151, 151);
   --focus-colour: rgba(53, 53, 53, 0.15);
   --unfocused-colour: rgba(0, 0, 0, 0.15);
   color: white;
   color-scheme: dark;

   .MuiFormLabel-root {
     color: var(--text-secondary);

     .MuiFormLabel-asterisk {
       color: var(--asterisk-colour);
     }
   }

   &.shake {
     animation-name: shake;
     animation-fill-mode: forwards;
     animation-duration: 0.3s;
     animation-timing-function: ease;
   }

   .MuiFilledInput-root {
     background-color: var(--unfocused-colour);
     color: var(--text-primary);
   }

   .MuiFilledInput-underline:before {
     border-bottom: 1px solid Settings.$secondaryColor;
   }
   .MuiFilledInput-underline:after {
     border-bottom: 2px solid #2737e5;
   }

   .MuiFormHelperText-root {
     color: var(--text-secondary);
     .asterisk {
       color: var(--asterisk-colour);
     }
   }

   &.Mui-focused {
     .MuiFormLabel-root {
       color: var(--text-primary);
     }

     .MuiFilledInput-root {
       background-color: var(--focus-colour);
     }
   }

   // removes the colour that google chrome autofill adds
   input:-webkit-autofill,
   input:-webkit-autofill:focus {
     color-scheme: dark;
     transition: background-color 600000s 0s, color 600000s 0s;
   }

   &:hover {
     .MuiFilledInput-root {
       background-color: var(--focus-colour);
     }
     .MuiFilledInput-underline:before {
       border-bottom: 1px solid Settings.$secondaryColor;
     }
   }
 }
}

.DayInput {
  width: 75px;
}
.MonthInput {
  width: 125px;
}
.YearInput {
  width: 100px;
}
