@use "../../../settings/Settings";

@mixin hoverTransition {
  transition: 0.3s ease;
}

.dropzone {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  user-select: none;
  width: 400px;
  height: 300px;
  border: 1px solid Settings.$darkThemeThirdColor;
  position: relative;

  @include hoverTransition;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;

  &:not(.fileUploaded) {
    &:hover,
    &.draggingOver {
      p {
        color: black;
      }
      background-image: radial-gradient(
        Settings.$darkThemeThirdColor 0%,

        Settings.$darkThemeSecondColor 70%
      );
    }
  }

  .placeholderImage {
    margin-bottom: 10px;
  }
  
  .overlay {
    background-color: white;
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.1s;
  }

  &.draggingOver,
  &:hover.fileUploaded {
    .overlay {
      opacity: 0.15;
    }
  }

  > input {
    outline: none;
  }

  > p {
    margin-top: 2px;
    margin-bottom: 0;
    @include hoverTransition;

    &.extraBold {
      font-size: 18px;
      font-weight: 600;
    }
  }
}

.imagePreview {
  width: 100%;
  height: 100%;
  // expand image to fill the container
  object-fit: contain;
}
