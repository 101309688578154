@use "../../../../settings/Settings";

@mixin indentLists($indentation: 0) {
  .listItem {
    .row {
      padding-left: $indentation * 30px; // Apply indentation based on nesting level
    }
  }

  .list {
    @if $indentation < 5 {
      @include indentLists(
        $indentation + 1
      ); // Recursively call the mixin for nested lists
    }
  }
}

.list {
  margin: 0;

  list-style: none;
  color: white;
  user-select: none;
  display: flex;
  flex-direction: column;

  &.hidden {
    display: none;
  }
  .listItem {
    @include indentLists(0); // Call the mixin to indent the list items
    .row {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 5px 10px;
      width: 100%;
      height: 100%;
      cursor: pointer;

      &:visited,
      &:link {
        color: inherit;
      }
      &:hover {
        background-color: Settings.$darkThemeThirdColor;
      }
      &.active {
        background-color: Settings.$paleBlue;
      }
      &.expand {
        .expandIcon {
          transform: rotate(90deg);
        }
      }
    }

    .expandButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
    }
  }
}
