@use "../../../../settings/Settings";

.preblastSummary {
  flex: 1;
  background-color: Settings.$darkThemeSecondColor;
}
.box {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: Settings.$darkThemeSecondColor;
  margin: 20px 30px;
  padding: 20px;
}

.dataWrapper {
  padding: 15px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  .dataContainer {
    padding: 15px;
    //elevate
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
      0 2px 1px -1px rgba(0, 0, 0, 0.12);

    display: flex;
    flex-direction: column;

    .key {
      font-size: 14px;
    }

    .value {
      font-weight: 700;
    }
  }
}


.exportWrapper {
   position: absolute;
   top: 0;
   right: 0;
   padding: 20px 30px;
  .exportButton {
    padding: 10px 20px;
    display: flex;
    gap: 5px;
  }
}

.imageContainer {
   display: flex;
   justify-content: center;
   padding: 0  50px;
   width: 100%;
   height: 300px;
   img {
     width: 100%;
     height: contain;
     object-fit: cover;
   }
 }
